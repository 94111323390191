import { SVGAttributes } from 'react'
import { FaRunning } from 'react-icons/fa'
import { FaBluesky, FaXTwitter } from 'react-icons/fa6'
import { RiHandHeartFill } from 'react-icons/ri'
import { BadgeMap } from './Badges.types'

export const BADGES: BadgeMap = {
  twitter: {
    key: 'twitter',
    label: '𝕏 / Twitter',
    bgClassName: 'bg-gradient-to-br from-gray-950 to-gray-850',
    icon: FaXTwitter,
    href: '/analytics?view=twitter'
  },
  bluesky: {
    key: 'bluesky',
    label: 'Bluesky',
    bgClassName: 'bg-gradient-to-br from-sky-500 to-indigo-500',
    icon: FaBluesky,
    href: '/analytics?view=bluesky'
  },
  charitable: {
    key: 'donations',
    label: 'Charitable',
    bgClassName: 'bg-gradient-to-br from-green-500 to-cyan-500',
    icon: RiHandHeartFill
  },
  nodeRunner: {
    key: null,
    label: 'Node Runner',
    bgClassName: 'bg-gradient-to-br from-pink-400 to-rose-600',
    icon: FaRunning
  },
  consensus: {
    key: 'participation',
    label: 'Consensus Participant',
    bgClassName: 'bg-gradient-to-br from-green-500 to-cyan-500',
    href: '/analytics?view=consensus',
    icon: (props: SVGAttributes<SVGElement>) => (
      <svg
        viewBox="0 0 240 240"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
        fill="currentColor"
        height="1em"
        width="1em"
        {...props}
      >
        <g>
          <polygon points="120,123 33.1,183.9 33.1,220 120,159.1 206.9,220 206.9,183.9" />
          <polygon points="120,61.5 33.1,122.4 33.1,158.5 120,97.6 206.9,158.5 206.9,122.4" />
          <polygon points="120,0 33.1,60.9 33.1,97 120,36.1 206.9,97 206.9,60.9" />
        </g>
      </svg>
    )
  }
}

// Taken from https://codepen.io/thebabydino/pen/XbxKbL

export const hexagonClipPath =
  'polygon(47.5% 5.66987%, 48.2899% 5.30154%, 49.13176% 5.07596%, 50% 5%, 50.86824% 5.07596%, 51.7101% 5.30154%, 52.5% 5.66987%, 87.14102% 25.66987%, 87.85495% 26.16978%, 88.47124% 26.78606%, 88.97114% 27.5%, 89.33948% 28.2899%, 89.56505% 29.13176%, 89.64102% 30%, 89.64102% 70%, 89.56505% 70.86824%, 89.33948% 71.7101%, 88.97114% 72.5%, 88.47124% 73.21394%, 87.85495% 73.83022%, 87.14102% 74.33013%, 52.5% 94.33013%, 51.7101% 94.69846%, 50.86824% 94.92404%, 50% 95%, 49.13176% 94.92404%, 48.2899% 94.69846%, 47.5% 94.33013%, 12.85898% 74.33013%, 12.14505% 73.83022%, 11.52876% 73.21394%, 11.02886% 72.5%, 10.66052% 71.7101%, 10.43495% 70.86824%, 10.35898% 70%, 10.35898% 30%, 10.43495% 29.13176%, 10.66052% 28.2899%, 11.02886% 27.5%, 11.52876% 26.78606%, 12.14505% 26.16978%, 12.85898% 25.66987%)'
