import isIPFS from 'is-ipfs'
import LinkifyIt from 'linkify-it'
import tlds from 'tlds'
import { IPFS_GATEWAY_URL } from '@/data/constants'

const linkify = new LinkifyIt()

linkify.tlds(tlds)
linkify.add('ftp:', null)
linkify.add('ipfs://', {
  validate: (text, pos, self) => {
    // all characters from matched `ipfs://` to the end of the string
    const tail = text.slice(pos)

    if (!self.re.ipfs) {
      // matches <CID> or <CID>/<filename>
      self.re.ipfs = /^[a-zA-Z0-9]{46,}(\/[^\s\/?#]+)?(?=$|[^a-zA-Z0-9])/
    }

    if (!self.re.ipfsCid) {
      // matches <CID>
      self.re.ipfsCid = /^[a-zA-Z0-9]{46,}(?=$|[^a-zA-Z0-9])/
    }

    if (self.re.ipfs.test(tail)) {
      const matchCid = tail.match(self.re.ipfsCid)
      const matchPath = tail.match(self.re.ipfs)

      if (!matchCid || !matchPath) {
        return false
      }

      // validate CID (hash) and path
      if (!isIPFS.cid(matchCid[0]) || !isIPFS.path(`/ipfs/${matchPath[0]}`)) {
        return false
      }

      return matchPath[0].length || 0
    }

    return 0
  },
  normalize: (match) => {
    const trimmed = match.raw.split(`ipfs://`)[1]
    match.url = `${IPFS_GATEWAY_URL}/ipfs/${trimmed}`
  }
})
linkify.add('algo:', {
  validate: (text, pos, self) => {
    // all characters from matched `algo:` to the end of the string
    const tail = text.slice(pos)

    if (!self.re.algo) {
      self.re.algo = /[A-Z2-7]{58}/
    }

    if (self.re.algo.test(tail)) {
      const match = tail.match(self.re.algo)

      if (!match) {
        return false
      }

      return match[0].length || 0
    }

    return 0
  },
  normalize: (match) => {
    const trimmed = match.raw.split(`algo:`)[1]
    match.text = trimmed
    match.url = `https://allo.info/account/${trimmed}`
  }
})

export { linkify }
